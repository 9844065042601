import React from "react";

export default function FooterSection() {
    return (
        <section className="bg-gradient py-3">
            <div className="container">
                <div className="row">
                    <div className="col text-center text-white">
                        Made with <span role="img" aria-label="<3">❤️</span> for clickles
                    </div>
                </div>
            </div>
        </section>
    )
}
