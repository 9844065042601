import React from "react";
import {VideoItem} from "../items/video";

type TwitchSectionStateType = {clips: Array<{url: string, title: string, thumb: string, views: number, created: string}>}

export default class HighlightSection extends React.Component<{}, TwitchSectionStateType>{
    constructor(props: {}) {
        super(props);

        this.state = {clips: []}
    }

    componentDidMount() {
        this.getData()
    }

    async getData() {
        let resp = await (await fetch("https://clickles.tv/api/twitch/clips?limit=6")).json();

        if (!resp.success) {
            return;
        }

        this.setState({clips: resp.data});
    }

    render() {
        return (
            <section className="py-5 bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col text-center">
                            <h1 className="text-gradient">Highlights</h1>

                            <div className="row mt-5 row justify-content-center">
                                {this.state.clips.map((clip) => { return (<VideoItem  thumb={clip.thumb} url={clip.url} title={clip.title} />) })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
