import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord, faInstagram, faTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";

export default function AboutSection() {
    return (
        <section className="py-5" id="about">
            <div className="container">
                <div className="row">
                    <div className="col-xl-10 col-lg-11 mx-auto">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <h1 className="text-gradient text-center">Hallo, Ich bins Cliggi</h1>
                                <p className="description mt-4">
                                    Mein Name ist Dario, ich bin 21 Jahre alt (nein du hast dich nicht verlesen ich bin
                                    wirklich 21) und ich studiere Geographie im {Math.ceil((Date.now() - 1569888000000) / (3600 * 24 * 365 / 2 *1000))}. Semester in München. Nebenbei liebe
                                    ich es zu zocken und möchte diesen Content hier gerne mit euch teilen! Ich streame
                                    oftmals mit Freunden auf <a href="https://dc.clickles.tv" target="_blank" style={{fontWeight: "bold"}} rel="noopener noreferrer">Discord</a> oder gerne auch mal alleine kommt ganz drauf
                                    an was ich spiele und wie ich drauf bin &lt;3. Ansonsteeeen joa fühlt euch wohl,
                                    ihr seid herzlich willkommen :)
                                </p>
                            </div>
                            <div className="col-md-6 px-5">
                                <div className="bg-gradient rounded-circle p-2 m-5">
                                    <img src="/image/dario.png" alt="Dario" className="rounded-circle img-fluid" />
                                </div>

                                <div className="social-icons text-center">
                                    <a href="https://twitter.com/clicklestv" className="bg-gradient" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={faTwitter} className="text-white mx-2" />
                                    </a>
                                    <a href="https://www.instagram.com/clickles.tv/" className="bg-gradient" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={faInstagram} className="text-white mx-2" />
                                    </a>
                                    <a href="https://www.youtube.com/channel/UCc5-0yE3nMPVd_z_XYmjbBQ" className="bg-gradient" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={faYoutube} className="text-white mx-2" />
                                    </a>
                                    <a href="https://dc.clickles.tv" className="bg-gradient" target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={faDiscord} className="text-white mx-2" />
                                    </a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}
