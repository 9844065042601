import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleDown} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-scroll";

export default function MastheadSection() {
    return (
        <header className="bg-gradient d-flex height-fix vh-100" id="home">
            <div className="container my-auto">
                <div className="row">
                    <div className="col-xl-10 col-lg-11 mx-auto">
                        <div className="embed-responsive embed-responsive-16by9">
                            <iframe className="stream-frame embed-responsive-item animated jackInTheBox bg-gradient-reverse"
                                    title="twitch"
                                    allowFullScreen={true}
                                    src="https://player.twitch.tv/?channel=clickles&autoplay=true&parent=localhost&parent=clickles.tv"
                            />
                        </div>
                    </div>
                </div>

                <div className="row pt-5">
                    <div className="col text-center">
                        <Link to="about" smooth className="btn btn-primary btn-lg text-primary animated bounceIn border-0" style={{backgroundColor: "white"}}>
                            Wer bin ich <FontAwesomeIcon icon={faAngleDoubleDown} className="mx-1" />
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    )
}
