import React from 'react';
import ReactDOM from 'react-dom';
import Application from './application';

import './styles/main.scss';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(<Application />, document.getElementById('root'));

serviceWorker.unregister();
