import React from "react";
import {VideoItem} from "../items/video";

type YoutubeSectionStateType = {videos: Array<{url: string, title: string, thumb: string, views: number, created: string}>}

export default class VideoSection extends React.Component<{}, YoutubeSectionStateType>{
    constructor(props: {}) {
        super(props);

        this.state = {videos: []}
    }

    componentDidMount() {
        this.getData()
    }

    async getData() {
        let resp = await (await fetch("https://clickles.tv/api/youtube/videos?limit=6")).json();

        if (!resp.success) {
            return;
        }

        this.setState({videos: resp.data});
    }

    render() {
        return (
            <section className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col text-center">
                            <h1 className="text-gradient">Videos</h1>

                            <div className="row mt-5 row justify-content-center">
                                {this.state.videos.map((clip) => { return (<VideoItem  thumb={clip.thumb} url={clip.url} title={clip.title} />) })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
