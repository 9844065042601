import React from "react";

interface VideoItemProps {
    url: string;
    thumb: string;
    title: string
}

export function VideoItem(props: VideoItemProps) {
    return (
        <div className={"col-md-4 align-items-stretch mb-4"}>
            <a href={props.url} className={"text-secondary"} target={"_blank"} rel="noopener noreferrer">
                <div className={"card h-100"}>
                    <img className={"card-img-top"} src={props.thumb} alt={props.title} />
                    <div className={"card-body"}>
                        <h5 className={"card-title text-center text-gradient"}>{props.title}</h5>
                    </div>
                </div>
            </a>
        </div>
    )
}

