import React from 'react';

import MastheadSection from "./components/masthead";
import AboutSection from "./components/about";
import HighlightSection from "./components/highlights";
import VideoSection from "./components/videos";
import FooterSection from "./components/footer";

export default class Application extends React.Component {
    render(): React.ReactElement {
        return (
            <div>
                <MastheadSection/>
                <AboutSection/>
                <HighlightSection/>
                <VideoSection/>
                <FooterSection/>
            </div>
        );
    }
}
